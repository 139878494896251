<template>
  <div class="login-page" id="print">

    <v-container>

      <v-overlay :value="overlay" color="white" opacity="1">
        <v-progress-circular color="gifty" indeterminate size="50"></v-progress-circular>
      </v-overlay>


      <v-row align="center" justify="center" class="mt-16">
        <v-col cols="12" lg="7">
          <div v-if="error">
            <v-card class="rounded" flat>

              <v-card-text class="pa-6 pa-lg-12">

                <div class="mb-5 text-center">
                  <img alt="" src="../../assets/logo2.png"/>
                </div>

                <div class="text-center">

                  <v-icon color="red" size="80">mdi-alert-circle</v-icon>

                  <h2 class="mt-3 grey--text text--darken-4 mb-5">
                    Erreur
                    <small class="red--text">{{ responseCode }} 450 !!</small>
                  </h2>

                  <p>
                    {{ error }}
                  </p>

                  <div>

                    <v-btn text @click="showOrder" color="gifty">
                      <v-icon left>mdi-refresh</v-icon>
                      Rafraîchir la page
                    </v-btn>

                    <v-btn href="https://gifty-dz.com/#contact"
                           target="_blank"
                           color="gifty"
                           text>
                      <v-icon left>mdi-help-circle-outline</v-icon>
                      contacter le support
                    </v-btn>
                  </div>

                </div>

              </v-card-text>
            </v-card>
          </div>

          <div v-else>

            <v-card v-if="isShowLoading" class="rounded" flat>
              <v-card-text class="text-center pa-6 pa-lg-12">
                <v-skeleton-loader type="avatar,article,actions"/>
              </v-card-text>
            </v-card>

            <v-card v-else class="rounded" flat>

              <v-card-text class="pa-6 pa-lg-12">

                <div class="mb-5 text-center">
                  <img alt="" src="../../assets/logo2.png"/>
                </div>

                <div class="text-center">
                  <v-icon v-if="epayment"
                          :color="epayment?.status?.color"
                          size="80">
                    {{
                      epayment?.status?.color === "success" ? "mdi-check-circle-outline" : "mdi-alert-circle-outline"
                    }}
                  </v-icon>

                  <v-icon color="error" size="80" v-else>
                    {{ "mdi-alert-circle" }}
                  </v-icon>
                </div>

                <h4 class="mt-3 mb-5 text-center font-weight-medium">
                  {{ epayment.message }}
                </h4>

                <div v-if="epayment && epayment?.status?.color === 'success'">

                  <v-alert color="success" text>
                    <p class="mb-1">
                      Salut,
                      <strong> {{ epayment?.account?.user.name }} </strong>
                      ! Ceci est un message de confirmation de votre paiement.
                    </p>
                    <p class="mb-0">
                      Une copie d'une facture détaillée peut être trouvée ici
                    </p>
                  </v-alert>

                  <v-simple-table class="table-border rounded mb-5" v-if="epayment">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <td class="font-weight-medium">
                          Nom complet
                        </td>
                        <td>
                          {{ epayment?.transaction?.account?.user?.name }}
                          {{ epayment?.voucher_order?.account?.user?.name }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          Identifiant de la transaction
                        </td>
                        <td>
                          {{ epayment.getway_order_id }}
                        </td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          Numéro de commande
                        </td>
                        <td>{{ epayment.id }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">
                          Numéro d'autorisation
                        </td>
                        <td>{{ epayment.code }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-medium">Date</td>
                        <td>
                          {{ epayment.created_at }}
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">
                          Moyen de paiement
                        </td>
                        <td>
                          Carte CIB & Edahabia
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">Type</td>
                        <td>
                          {{ epayment.transaction ? "Consommation" : "" }}
                          {{ epayment.voucher_order ? "Commander un voucher" : "" }}
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">
                          Carte cadeau
                        </td>
                        <td>
                          {{ epayment.transaction ? epayment.transaction.service.name : "" }}
                          {{ epayment.voucher_order ? epayment.voucher_order.service.name : "" }}
                        </td>
                      </tr>

                      <tr>
                        <td class="font-weight-medium">
                          Montant Service
                        </td>
                        <td class="font-weight-bold">
                          {{ epayment.transaction ? CurrencyFormatting(epayment.transaction.amount) : "" }}
                          {{ epayment.voucher_order ? CurrencyFormatting(epayment.voucher_order.amount) : "" }} DZD
                        </td>
                      </tr>

                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-alert color="gifty" text class="mt-5">
                    <h2 class="text-center">
                      Total : {{ epayment.transaction ? CurrencyFormatting(epayment.transaction.amount) : "" }}
                      {{ epayment.voucher_order ? CurrencyFormatting(epayment.voucher_order.amount) : "" }}
                      DZD
                    </h2>
                  </v-alert>

                  <div class="noprint">
                    <v-btn block
                           class="mb-3"
                           large
                           depressed
                           @click="printPage">
                      <v-icon color="gifty" left>mdi-printer</v-icon>
                      Imprimer
                    </v-btn>

                    <v-btn block
                           class="text-none"
                           large
                           depressed
                           @click="downloadInvoice">
                      <v-icon color="gifty" left>mdi-file-pdf-box</v-icon>
                      Télécharger en PDF
                    </v-btn>

                    <v-btn block
                           class="text-none mt-3"
                           large
                           depressed
                           @click="
                        [
                          (dialog = true),
                          (errors = {}),
                          (successMessage = false),
                        ]
                      "
                    >
                      <v-icon color="gifty" left>mdi-email-send</v-icon>
                      Envoyer par e-mail
                    </v-btn>
                  </div>
                </div>

                <div v-else></div>

                <div class="text-center mt-10 noprint">
                  <img alt="logo" src="@/assets/green_number.png" width="200"/>
                </div>

              </v-card-text>
            </v-card>
          </div>

        </v-col>
      </v-row>
    </v-container>


    <v-dialog v-model="dialog" max-width="450" persistent>
      <v-card :disabled="btnLoading" :loading="btnLoading">
        <v-card-title>
          Envoyer par e-mail
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-8">
          <v-alert v-if="successMessage" border="left" text type="success">
            La facture a été envoyée à votre adresse e-mail avec succès.
          </v-alert>

          <p class="font-weight-medium">
            Entrer votre email <span class="red--text">*</span>
          </p>

          <v-text-field
              v-model="email"
              :error-messages="errors.email"
              color="gifty"
              outlined
              placeholder="Entrer votre email ici..."
              @input="errors.email = null"
          />

          <v-btn
              :loading="btnLoading"
              block
              color="gifty"
              dark
              depressed
              large
              @click="sendInvoiceEmail"
          >
            Envoyer
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      isShowLoading: false,
      overlay: false,
      btnLoading: false,
      dialog: false,
      error: null,
      email: null,
      successMessage: false,
      errors: {},
      epayment: {},
      responseCode: null,
    };
  },
  methods: {
    showOrder() {
      this.isShowLoading = true;
      let token = this.$route.params.token;
      axios.get(process.env.VUE_APP_BASE_URL + "v1/e-payments/origin-order/" + token).then((res) => {
        this.isShowLoading = false;
        this.epayment = res.data;
      }).catch((err) => {
        this.isShowLoading = false;
        console.log(err);
      });
    },
    downloadInvoice() {
      let token = this.$route.params.token;
      window.open(
          process.env.VUE_APP_BASE_URL +
          "v1/e-payments/invoice/download-pdf?token=" +
          token
      );
    },
    sendInvoiceEmail() {
      this.successMessage = false;
      this.btnLoading = true;
      this.errors = {};
      axios.post(process.env.VUE_APP_BASE_URL + "v1/e-payments/invoice/send-email",
          {
            email: this.email,
            token: this.$route.params.token,
          }).then(() => {
        this.btnLoading = false;
        this.successMessage = true;
      }).catch((err) => {
        this.errors = err.response.data.errors;
        this.btnLoading = false;
        console.log(err);
      });
    },
    call() {
      window.open("tel:3020", "_self");
    },
    goToApk() {
      window.open(process.env.VUE_APP_gifty_deep_link, "_blank");
    },
    printPage() {
      const prtHtml = document.getElementById("app").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      stylesHtml += '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css">';
      const WinPrint = window.open(
          "",
          "",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },
  },
  created() {
    this.showOrder();
  },
};
</script>
<style>
@media print {
  .noprint {
    display: none;
  }
  .mt-16 {
    margin-top: 0 !important;
  }
}
</style>